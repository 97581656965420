(function ($, Drupal) {
  Drupal.behaviors.sitewideFooterFormatterV1 = {
    elems: {},
    attach: function (context) {
      // Get our variables
      var self = this;

      self.elems.$module = $('.js-sitewide-footer-formatter-v1', context);
      self.elems.$countryList = $('.js-footer-country-list', self.elems.$module);
      self.elems.$countrySelectbox = $('.js-footer-country-select', self.elems.$module);

      // Perform our functions
      self.countryList();
    },

    countryList: function () {
      var self = this;

      // Turn our menu into a select box
      self.elems.$countryList.find('.menu li').each(function () {
        var href = $(this).find('a').attr('href');
        var text = $(this).text();
        var option = '<option value="' + href + '">' + text + '</option>';

        self.elems.$countrySelectbox.append(option);
      });

      // Initiate
      self.elems.$countrySelectbox.trigger('select.enabled');

      self.elems.$countrySelectbox.on('change', function (event) {
        // When a country is selected, open it in a new window
        var urlToOpen = $(this).val();
        var win = window.open(urlToOpen, '_blank');
      });
    }
  };
})(jQuery, Drupal);
